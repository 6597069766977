/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Stop transtion on resize
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

// = jQuery example - (function ($) {'use strict';})(jQuery); $.fn.ClassName = function() {};

const transitionDelay = () => {
    let _oResizeId;
    let _oHtml = document.getElementsByTagName('html')[0];

    // = Trigger on resize
    window.addEventListener('resize', function () {
        clearTimeout(_oResizeId);
        _oHtml.classList.add('no-transition');
        _oResizeId = setTimeout(doneResizing, 500);
    });

    // = Trigger on orientationchange
    window.addEventListener('orientationchange', function () {
        clearTimeout(_oResizeId);
        _oHtml.classList.add('no-transition');
        _oResizeId = setTimeout(doneResizing, 500);
    });

    function doneResizing(){
        _oHtml.classList.remove('no-transition');
    }
};

// = Init
transitionDelay();
