/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Sticky header
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

// = jQuery example - (function ($) {'use strict';})(jQuery); $.fn.ClassName = function() {};



function throttle(targetFunc, delay) {
    let lastFunc;
    let lastTime;

    return function () {
        const _this = this;
        const args = arguments;

        if (!lastTime) {
            targetFunc.apply(_this, args);
            lastTime = Date.now();
        } else {
            clearTimeout(lastFunc);
            lastFunc = setTimeout(function () {
                targetFunc.apply(_this, args);
                lastTime = Date.now();
            }, delay - (Date.now() - lastTime));
        }
    };
}


const pageScrolled = () => {
    let _oBody = document.getElementsByTagName('body')[0];
    let _iScrollPos = window.scrollY;
    let _bScrollStatus = _iScrollPos >= 1;


    if (_bScrollStatus === true && !_oBody.classList.contains('is--scrolled')) {
        _oBody.classList.add('is--scrolled');
        return;
    }

    if (_bScrollStatus === false && _oBody.classList.contains('is--scrolled')) {
        _oBody.classList.remove('is--scrolled');
        return;
    }


};

// = Init
pageScrolled();

// = Trigger on scroll
window.addEventListener('scroll', throttle(pageScrolled,100));
