/**
 * This Software is the property of dotfly and is protected
 * by copyright law - it is NOT Freeware.
 *
 * Any unauthorized use of this software without a valid license key
 * is a violation of the license agreement and will be prosecuted by
 * civil and criminal law.
 *
 * @category   Add product
 * @author     Sascha Doerrenbach <sascha.doerrenbach@dotfly.de>
 * @license    http://www.dotfly.de Commercial
 * @link       http://www.dotfly.de
 */

(function ($) {
    'use strict';

    $.fn.initAddProduct = function() {
        var _oTrigger = $('.js-add-wishlist');

        _oTrigger.on('change', function(){
            //$("input[type='hidden'][name='fnc']").remove();
            var _oForm = $('.js-oxProductForm');
            var _oTarget = $(this);

            //Head
            var _oWishlistTotal = $('.js-total-wishlist');
            var _oWistlistTotalVal = _oWishlistTotal.text();
            var _oWistlistVal = Number(_oWistlistTotalVal);

            //Dropdown
            // var _iCount = _oTarget.next().find('.js-count-wishlist');
            var _iCount = $('.js-count-wishlist');
            var _iCountVal = _iCount.text();
            var _iCountValNumber = Number(_iCountVal);
            var _iCountSum = 0;

            if(_oTarget.is(':checked')) {
                _iCountSum = parseInt(_iCountValNumber + 1);
                _iCount.text(_iCountSum);
                //Google tag manager
                _oTarget.addClass('gtm_is--unchecked');
                _oTarget.removeClass('gtm_is--checked');
            }else {
                _iCountSum = parseInt(_iCountValNumber - 1);
                _iCount.text(_iCountSum);
                //Google tag manager
                _oTarget.removeClass('gtm_is--unchecked');
                _oTarget.addClass('gtm_is--checked');
            }

            //Form
            var _sFormUrl = _oForm.attr('action');
            var _sUrl = encodeURI(_sFormUrl);
            var _sFormData = _oForm.serializeArray();
            _sFormData.push({
                // amount: $('#amountToBasket').val(),
                amount: 1,
                name: 'fnc',
                value: 'toWishList'
            });
            // console.log(_sFormData);
            $.ajax({
                url: _sUrl,
                type: 'POST',
                data: _sFormData,
                success: function(data) {
                    if(_oWishlistTotal.length) {
                        $.each(data, function (i, v) {
                            var _iSumTotal = 0;

                            if (v.checked === 0) {
                                _iSumTotal = parseInt(_oWistlistVal - 1);

                                if (_iSumTotal < 0) {
                                    _iSumTotal = 0;
                                }

                                _oWishlistTotal.text(_iSumTotal);
                            } else {
                                _iSumTotal = parseInt(_oWistlistVal + 1);
                                _oWishlistTotal.text(_iSumTotal);
                            }
                        });
                    }
                }
            });
        });
    };
    $.fn.downloadCsv = function() {
        $('.button__downloadcsv').click(function() {
            var _form = $('#download')
            _form.submit();
        });
    }
})(jQuery);
